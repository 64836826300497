import React from "react";
import { graphql } from "gatsby";
import Include from "@coremedia/headless-client-app/src/utils/ViewDispatcher/Include";
import SEO from "../components/seo";

interface PageProps {
  data: any;
}

const DetailPage: React.FC<PageProps> = ({ data }) => {
  return (
    <>
      <SEO title={data.coremedia.content.content.title} />
      <div id="cm-placement-main" className="cm-placement cm-placement--main">
        <div className="cm-details-container">
          {data.coremedia.content.content && <Include self={data.coremedia.content.content} view={"asDetail"} />}
        </div>
      </div>
    </>
  );
};

export default DetailPage;

export const query = graphql`
  query DetailQuery($id: String!) {
    coremedia {
      content {
        content(id: $id) {
          ...DetailTeasable
          ...DetailPerson
          ...DetailCMProduct
        }
      }
    }
  }
`;
